<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header
                    :title="$t('courses')"
                    :other-button="{show:true,icon:'ri-file-chart-line',title:$t('attendance_list'),emit:'attendanceList'}"
                    @attendanceList="openModal()"
                    @filter-div-status="filterStatus = $event"/>
            </template>
            <template v-slot:header-mobile>
                <header-mobile
                    :title="$t('courses')"
                    @filter-div-status="filterStatus = $event"/>
            </template>

            <b-row class="pl-3 pb-3">
                <b-col sm="6" md="4">
                    <b-form-group :label="$t('semester_1')">
                        <semesters-selectbox :setActive="true" v-model="queryParams.semester_id"/>
                    </b-form-group>
                </b-col>
                <div class="col-12" v-if="data && data.lms_link">
                    <a :href="data.lms_link.url" target="_blank"><img style="max-height: 48px" :src="data.lms_link.logo"/></a>
                </div>
            </b-row>


            <div class="mb-5 align-items-center">
                <b-card-group deck>
                    <div v-for="(item, key) in courses" class="col-12 col-sm-6 col-md-6 col-lg-3 mb-4" :key="key">
                        <b-card bg-variant="light" :header="item.course_code" class="text-center cursor p">
                            <b-card-text>
                                <b-form-group
                                    :label="$t('course_name')">
                                    {{ item.course_name }}
                                </b-form-group>
                                <b-form-group :label="$t('section')">
                                    {{ getObjectValue(item, 'section_number') }}
                                </b-form-group>
                                <b-form-group :label="$t('instructor')">
                                    {{ getObjectValue(item, 'instructor') }}
                                </b-form-group>
                                <b-form-group :label="$t('number_of_unexcused_absence_hours')">
                                    {{ getObjectValue(item, 'attendance_hours') }}
                                </b-form-group>
                                <b-form-group :label="$t('letter_grade')">
                                    {{ getObjectValue(item, 'letter_grade') }}
                                </b-form-group>
                            </b-card-text>
                        </b-card>
                    </div>
                </b-card-group>
            </div>

            <CommonModal ref="studentAttendanceRecordsModal" :onHideOnlyX="true" @bHideModalHeaderClose="clear">
                <template v-slot:CommonModalTitle>{{ $t('student_attendance_records') }}</template>
                <template v-slot:CommonModalContent>
                    <div>
                        <ValidationObserver ref="formModalValidate">
                            <b-col sm="12">
                                <ValidationProvider name="academic_year" rules="required" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('academic_year')">
                                        <academic-years-selectbox
                                            v-model="form.academic_year"
                                            :validate-error="errors[0]"
                                        >

                                        </academic-years-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col sm="12">
                                <ValidationProvider name="semester" rules="required" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('semester')">
                                        <parameter-selectbox
                                            code="semester"
                                            v-model="form.semester"
                                            :validate-error="errors[0]"
                                        ></parameter-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                        </ValidationObserver>
                    </div>
                    <div class="d-flex justify-content-center mt-2">
                        <b-button variant="primary" @click="downloadAttendanceList()">
                            {{ $t('download') }}
                        </b-button>
                    </div>
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
import AppLayout from "@/layouts/AppLayout.vue";
import Header from "@/layouts/AppLayout/Header.vue";
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile.vue";

import SemestersSelectbox from "@/components/interactive-fields/SemestersSelectbox.vue";

import StudentProgramService from "@/services/StudentProgramService";

import qs from "qs";
import Base from "@/plugins/Base";
import StudentDocumentService from "@/services/StudentDocumentService";
import {EventBus} from "@/main";
import showErrors from "@/helpers/showErrors";
import AcademicYearsSelectbox from "@/components/interactive-fields/AcademicYearsSelectbox.vue";


export default {
    components: {
        AcademicYearsSelectbox,
        AppLayout,
        Header,
        HeaderMobile,


        SemestersSelectbox,
    },
    data() {
        return {
            form:{},
            courses: [],
            data: null,
            filterStatus: true,
            queryParams: {person: null, semester_id: null},
        }
    },
    created() {
        this.queryParams.person = Base.LocalStorage.get('user');

        this.getRows()

    },
    methods: {
        clear(){
            this.form={}
        },

        getRows() {
            if(!this.queryParams.semester_id){
                return;
            }
            if (this.queryParams.person && this.queryParams.person.id) {
                let config = {
                    params: {
                        semester_id: this.queryParams.semester_id
                    },
                    paramsSerializer: (params) => qs.stringify(params, {encode: false})
                }
                StudentProgramService
                    .getStudentCourses(config)
                    .then(res => {
                        this.setCourses(res)
                    })
                    .catch(err => this.showErrors(err))
            }
        },
       async openModal(){
           this.$refs.studentAttendanceRecordsModal.$refs.commonModal.show()
        },
      async downloadAttendanceList(){
            const isValid=await this.$refs.formModalValidate.validate()
            if (!isValid)return
            if(!this.checkPermission('studentdocument_dailyattendancelist')){
                this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
            }

            let data={
                params:{
                    filter:{
                       ...this.form
                    }
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false})
            }

            StudentDocumentService.studentAttendanceRecordsReport(this.queryParams.person?.student?.student_program[0]?.id,data)
                .then(response=>{
                    const fileName=this.queryParams.person?.student?.student_number + '-' + this.$t('student_attendance_records') + '.pdf'
                    EventBus.$emit('pdfViewerModalShow', {
                        pdfFileData: response.data,
                        pdfFileName: fileName,
                    })
                }).catch(e => {
                showErrors(e, null, true)
            })


        },
        setCourses(res) {
            this.data = res.data.data;
            if(this.data.courses){
                this.courses = this.data.courses;
            }
            else {
                this.courses = this.data;
            }
            if (!this.queryParams.semester_id) {
                this.setActiveSemester(res)
            }
        },
        setActiveSemester(res) {
            this.data = res.data.data;
            if(this.data.courses){
                this.courses = this.data.courses;
            }
            else {
                this.courses = this.data;
            }
            if (this.courses && this.courses.length) {
                this.queryParams.semester_id = this.courses[0].semester_id
            }
        }
    },
    watch:{
        'queryParams.semester_id':function (newValue,oldValue){
            if(newValue != oldValue){
                this.getRows(this.setCourses)
            }
        }
    }
}
</script>
